<template>
    <v-card class="mt-3">
        <v-toolbar>
            <v-toolbar-title>
                Listă clienți
            </v-toolbar-title>
            <v-toolbar-items>
                <v-btn
                    color="success"
                    @click="addNewClient"
                >
                    Adaugă client
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <div v-if="clientsList && clientsList.length > 0">
                <v-row>
                    <v-col cols="12" sm="12" md="4" lg="3">
                        <v-text-field
                            v-model="searchQuery"
                            label="Caută după nume"
                            style="min-width: 200px;"
                            variant="outlined"
                            density="compact"
                            append-inner-icon="mdi-magnify"
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col v-if="this.isSuperUser" cols="12" sm="12" md="4" lg="3">
                        <v-autocomplete
                            v-model="selectedUsers"
                            :items="users"
                            item-title="full_name"
                            item-value="id"
                            label="Filtrează după agent"
                            multiple
                            clearable
                            chips
                            closable-chips
                            variant="outlined"
                            density="compact"
                            hide-details
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="3">
                        <v-select
                            v-model="importedClientFilter"
                            :items="importedClientFilterOptions"
                            label="Filtrare clienți"
                            variant="outlined"
                            item-title="title"
                            item-value="value"
                            density="compact"
                            hide-details
                        ></v-select>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-data-table
                    class="mt-3"
                    :headers="tableHeaders"
                    :hover="true"
                    :items="filteredClientsList"
                    :items-per-page="10"
                >
                    <template v-slot:item="{ item, index }">
                        <tr @click="editClient(item.raw)" style="cursor: pointer;"
                            :class="{
                                      'confirmed-text': isSuperUser && item.raw.status === 5,
                                      'danger-text': item.raw.to_be_rectified,
                                      'info-text': item.raw.status === 7,
                                    }">
                            <!-- Index Column -->
                            <td>{{ index + 1 }}</td>

                            <!-- Name Column -->
                            <td>{{ item.raw.last_name }}, {{ item.raw.first_name }}</td>

                            <td>
                                <v-checkbox
                                    v-model="item.raw.was_contacted"
                                    readonly
                                    hide-details
                                    disabled
                                >

                                </v-checkbox>
                            </td>

                            <td v-if="isSuperUser">{{ item.raw.user_full_name }}</td>

                            <!-- Address Column -->
                            <td>
                                <div class="ellipsis-text">
                                    {{ formatStreet(item.raw.street) }}
                                    <template v-if="item.raw.number"> nr. {{ item.raw.number }}</template>
                                    <template v-if="item.raw.apartment">, Ap. {{ item.raw.apartment }}</template>
                                    <template v-if="item.raw.city">, {{ item.raw.city }}</template>
                                    <template v-if="item.raw.postal_code">, {{ item.raw.postal_code }}</template>
                                    <template v-if="getRegionName(item.raw.region)">, {{
                                            getRegionName(item.raw.region)
                                        }}
                                    </template>
                                </div>
                                <v-tooltip location="top" activator="parent">
                                    <span>
                                        {{ formatStreet(item.raw.street) }}
                                        <template v-if="item.raw.number"> nr. {{ item.raw.number }}</template>
                                        <template v-if="item.raw.apartment">, Ap. {{ item.raw.apartment }}</template>
                                        <template v-if="item.raw.city">, {{ item.raw.city }}</template>
                                        <template v-if="item.raw.postal_code">, {{ item.raw.postal_code }}</template>
                                        <template v-if="getRegionName(item.raw.region)">, {{
                                                getRegionName(item.raw.region)
                                            }}</template>
                                    </span>
                                </v-tooltip>
                            </td>

                            <td>{{ item.raw.panel_number ? item.raw.panel_number : '---' }}</td>
                            <td>{{ item.raw.panel_power ? item.raw.panel_power : '---' }}</td>
                            <td>{{ item.raw.inverter_power ? item.raw.inverter_power : '---' }}</td>
                            <td>{{ item.raw.inverter_display }}</td>
                            <td>{{
                                    item.raw.existing_connection_type ? item.raw.existing_connection_type : '---'
                                }}
                            </td>

                            <!-- Documents Column -->
                            <td>
                                <v-chip
                                    :color="getDocumentChipColor(item.raw)"
                                    small
                                    dark
                                >
                                    {{ getDocumentCount(item.raw) }}/{{ item.raw.total_documents }}
                                    <v-tooltip location="top" activator="parent">
                                        Documente disponibile: <br/>
                                        {{ getDocumentList(item.raw) }}
                                    </v-tooltip>
                                </v-chip>
                            </td>

                            <!-- Verifications Column -->
                            <td>
                                <v-chip
                                    :color="getVerificationChipColor(item.raw)"
                                    small
                                    dark
                                    @click.stop="openVerificationModal(item.raw)"
                                    class="cursor-pointer"
                                >
                                    {{ getVerificationTrueCount(item.raw) }}/10
                                </v-chip>
                            </td>

                            <!-- Status Column -->
                            <td>{{ getStatusTitle(item.raw.status) }}</td>

                            <!-- Actions Column -->
                            <td @click.stop>
                                <v-btn
                                    icon
                                    variant="plain"
                                    density="compact"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                    <v-menu
                                        offset-y
                                        transition="scale-transition"
                                        activator="parent"
                                    >

                                        <v-list>
                                            <v-list-item @click="editClient(item.raw)">
                                                <v-list-item-title>
                                                    <v-icon left>mdi-pencil</v-icon>
                                                    Editează
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="removeClient(item.raw)">
                                                <v-list-item-title>
                                                    <v-icon left>mdi-delete</v-icon>
                                                    Șterge
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="openVerificationModal(item.raw)">
                                                <v-list-item-title>
                                                    <v-icon left>mdi-check</v-icon>
                                                    Verifică
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
            <div v-else class="mt-5 d-flex justify-content-center">
                <h6>Nu există date disponibile.</h6>
            </div>
        </v-card-text>
        <v-dialog
            v-model="showVerificationModal"
            max-width="800px"
        >
            <v-card>
                <v-card-title class="headline">
                    Verificare Documente
                </v-card-title>
                <v-card-text>
                    <v-form ref="verificationForm">
                        <v-container>
                            <v-row v-for="(question, index) in verificationQuestions" :key="index">
                                <v-col cols="12">
                                    <v-row align="center">
                                        <v-col cols="10">
                                            <p>{{ index + 1 }}. {{ question.text }}</p>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-switch
                                                v-model="question.answer"
                                                :label="question.answer ? 'Da' : 'Nu'"
                                                color="success"
                                                inset
                                            ></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn @click="selectAllVerificationQuestions()">
                                    Selectează toate
                                </v-btn>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions class="d-flex flex-row justify-content-end">
                    <v-btn
                        color="danger"
                        @click="showVerificationModal = false"
                    >
                        Anulează
                    </v-btn>
                    <v-btn
                        color="success"
                        @click="submitVerification"
                    >
                        Salvează Verificarea
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import apiClient from "@/utils/apiClient";
import {showAlertModal} from "@/utils/utils";
import {VDataTable} from "vuetify/labs/components";

export default {
    name: "ClientsPrecontracts",
    components: {
        VDataTable,
    },
    data() {
        return {
            sidebarLinks: [
                {
                    name: 'Clienți RePower',
                    link: '#section1',
                    id: 'section1',
                },
            ],
            clientsList: [],
            users: [],
            selectedUsers: [],
            regions: [],
            cities: [],
            availableStatusOptions: [
                {title: 'De verificat', value: 1},
                {title: 'În verificare', value: 2},
                {title: 'Verificat', value: 3},
                {title: 'Finalizat', value: 4},
                ...(this.isSuperUser ? [{title: 'Confirmat', value: 5}] : []),
                {title: 'De rectificat', value: 6},
                {title: 'Rectificat', value: 7}, // Added this line
            ],
            tableHeaders: [],
            focusedRow: null,
            isSuperUser: false,
            showVerificationModal: false,
            selectedClient: null,
            verificationQuestions: [
                {
                    text: 'Beneficiarul final este persoana fizică cu domiciliul în România și datele de identificare sunt cele din documentul de identitate (BI/CI/Pasaport)? Se verifică: CNP-ul și numele și prenumele.',
                    answer: false,
                },
                {
                    text: 'Extrasul de carte funciară pe numele beneficiarului final este anexat?',
                    answer: false,
                },
                {
                    text: 'Extrasul de carte funciară pe numele beneficiarului final este eliberat cu maxim 60 de zile calendaristice înainte de data depunerii cererii de finanțare?',
                    answer: false,
                },
                {
                    text: 'Datele de identificare menționate în extrasul de carte funciară se corelează cu cele din documentul de identitate (BI/CI) al beneficiarului final? Se verifică: numele și prenumele, adresa de domiciliu.',
                    answer: false,
                },
                {
                    text: 'Conform informațiilor din contractul comercial, clădirea obiect al proiectului este clădire rezidențială unifamilială?',
                    answer: false,
                },
                {
                    text: 'În cazul în care clădirea obiect al proiectului este deținută în coproprietate de mai multe persoane, sunt îndeplinite condițiile: beneficiarul final deține cel puțin 50% din suprafața utilă a clădirii și beneficiarul final și ceilalți proprietari își dau acordul cu privire la implementarea proiectului?',
                    answer: false,
                },
                {
                    text: 'Contractul comercial între persoana fizică, în calitate de beneficiar final, și solicitant este anexat și conține clauzele minime obligatorii?',
                    answer: false,
                },
                {
                    text: 'Datele de identificare ale părților contractante, menționate în contractul comercial, se corelează cu documentele de identificare ale părților?',
                    answer: false,
                },
                {
                    text: 'Adresa clădirii, obiect al proiectului, este corect menționată în cadrul contractului comercial?',
                    answer: false,
                },
                {
                    text: 'Obiectul contractului comercial corespunde cu activitățile eligibile și obligatorii prevăzute în ghidul specific la secțiunea 1.3?',
                    answer: false,
                },
            ],
            searchQuery: '',
            importedClientFilter: 'all',
        };
    },
    computed: {
        importedClientFilterOptions() {
            return [
                {title: 'Toți', value: 'all'},
                {title: 'Importați', value: 'imported'},
                {title: 'Manual', value: 'manual'},
                {title: 'De rectificat', value: 'to_be_rectified'},
                {title: 'De verificat', value: 1},
                {title: 'În verificare', value: 2},
                {title: 'Verificat', value: 3},
                {title: 'Finalizat', value: 4},
                ...(this.isSuperUser ? [{title: 'Confirmat', value: 5}] : []),
                {title: 'Rectificat', value: 7}, // Added this line
            ];
        },
        filteredClientsList() {
            return this.clientsList.filter(client => {
                // Filter by search query
                const matchesSearchQuery = this.searchQuery
                    ? `${client.first_name} ${client.last_name}`.toLowerCase().includes(this.searchQuery.toLowerCase())
                    : true;

                // Filter by selected user(s)
                const matchesSelectedUsers = this.selectedUsers.length
                    ? this.selectedUsers.includes(client.user)
                    : true;

                // Filter by imported status, client status, or 'to_be_rectified' field
                let matchesImportedOrStatusFilter = false;

                if (this.importedClientFilter === 'all') {
                    matchesImportedOrStatusFilter = true;
                } else if (this.importedClientFilter === 'imported') {
                    matchesImportedOrStatusFilter = client.is_imported_client;
                } else if (this.importedClientFilter === 'manual') {
                    matchesImportedOrStatusFilter = !client.is_imported_client;
                } else if (this.importedClientFilter === 'to_be_rectified') {
                    matchesImportedOrStatusFilter = client.to_be_rectified === true;
                } else if (typeof this.importedClientFilter === 'number') {
                    matchesImportedOrStatusFilter = client.status === this.importedClientFilter;
                }

                // If non-superuser selects "Finalizat" (status 4), also include clients with status 5
                if (!this.isSuperUser && this.importedClientFilter === 4) {
                    matchesImportedOrStatusFilter = [4, 5].includes(client.status);
                }

                return matchesSearchQuery && matchesSelectedUsers && matchesImportedOrStatusFilter;
            });
        },
    },
    async mounted() {
        this.$store.state.sidebarLinks = this.sidebarLinks;
        this.setSuperUser();
        this.initializeTableHeaders();
        await this.fetchClientsList(); // Fetch clients when component is mounted
        if (this.isSuperUser) {
            await this.loadUsersFromSession();
        }
        await this.loadRegionsAndCities(); // Load regions and cities from cities.json
    },
    methods: {
        /**
         * Conditionally prepends "Str." to the street name if it doesn't start with "Strada", "Str.", or "Sat".
         *
         * @param {string} street - The name of the street to format.
         * @returns {string} - The formatted street name.
         */
        formatStreet(street) {
            if (/^(Strada|Str\.|Sat)/i.test(street)) {
                return street;
            }
            return `Str. ${street}`;
        },
        /**
         * Loads a filtered list of users from session storage, including only those with associated clients.
         *
         * This method retrieves the entire list of users from session storage, filters it to include
         * only users associated with clients, and assigns the result to the `users` array.
         * This filtered list enables a targeted user selection interface in the component.
         *
         * @async
         * @method
         */
        async loadUsersFromSession() {
            // Retrieve the full list of users from session storage
            const allUsers = await JSON.parse(sessionStorage.getItem('myUsers')) || [];

            // Identify unique user IDs from the clientsList
            const userIdsWithClients = new Set(this.clientsList.map(client => client.user));

            // Filter users to include only those who have associated clients
            this.users = allUsers.filter(user => userIdsWithClients.has(user.id));
        },
        /**
         * Sets the table headers for displaying the client list, including conditionally visible columns.
         *
         * This method initializes the table header configuration based on the current user’s permissions,
         * including an 'Agent' column if the user is a superuser. The resulting configuration is assigned
         * to `tableHeaders`, controlling the columns displayed in the client list.
         *
         * @method
         */
        initializeTableHeaders() {
            this.tableHeaders = [
                {title: 'Nr. crt.', key: 'index', sortable: false},
                {title: 'Nume', key: 'last_name'},
                {title: 'Contactat', key: 'was_contacted'},
                // Conditionally add the Agent column
                ...(this.isSuperUser ? [{title: 'Agent', key: 'user_full_name'}] : []),
                {title: 'Adresă', key: 'address'},
                {title: 'Număr panouri', key: 'panel_number'},
                {title: 'Putere panou (W)', key: 'panel_power'},
                {title: 'Putere invertor (KW)', key: 'inverter_power'},
                {title: 'Invertor', key: 'inverter_display'},
                {title: 'Branșament', key: 'existing_connection_type'},
                {title: 'Documente', key: 'documents', sortable: false},
                {title: 'Verificări', key: 'verifications', sortable: false},
                {title: 'Status', key: 'status'},
                {title: 'Acțiuni', key: 'actions', sortable: false},
            ];
        },
        /**
         * Selects all verification questions for the client, setting their answers to true.
         * @method
         */
        selectAllVerificationQuestions() {
            this.verificationQuestions.forEach(question => question.answer = true)
        },
        /**
         * Sets the `isSuperUser` property based on session storage data.
         *
         * This method checks session storage for the current user's permissions and updates
         * `isSuperUser` accordingly. This property controls access to certain features, like
         * extended filtering and additional data columns.
         *
         * @method
         */
        setSuperUser() {
            this.isSuperUser = JSON.parse(sessionStorage.getItem('superUser'));
        },
        /**
         * Calculates the number of verification questions answered positively for a client.
         *
         * This method takes a `client` object as input, counts the positive answers in the
         * client’s `verification_answers`, and returns the count. It’s used to visually indicate
         * verification progress on the client list.
         *
         * @param {Object} client - The client object containing verification answers.
         * @returns {number} - The count of positive verification answers.
         */
        getVerificationTrueCount(client) {
            if (client.verification_answers && Array.isArray(client.verification_answers)) {
                return client.verification_answers.filter(answer => answer === true).length;
            }
            return 0;
        },
        /**
         * Returns the chip color for a client's verification status, based on completed verifications.
         *
         * This method evaluates the count of positive verification answers for the client and returns
         * a color string that visually indicates their verification progress (red, yellow, or green).
         *
         * @param {Object} client - The client object for whom the color will be determined.
         * @returns {string} - The color string ('error', 'warning', or 'success').
         */
        getVerificationChipColor(client) {
            const count = this.getVerificationTrueCount(client);
            if (count === 0) return 'error'; // Red color for 0/10
            if (count === 10) return 'success'; // Green color for 10/10
            return 'warning'; // Yellow color for 1-9/10
        },
        /**
         * Opens the verification modal and loads the client's current verification answers.
         *
         * This method sets the `selectedClient` and, if verification answers are available,
         * maps them to the `verificationQuestions` array, enabling existing answers to be shown
         * in the modal. It then sets `showVerificationModal` to true, opening the modal.
         *
         * @param {Object} client - The client object for whom the verification modal is being opened.
         */
        openVerificationModal(client) {
            this.selectedClient = client;
            // Load existing answers if any
            if (client.verification_answers && Array.isArray(client.verification_answers)) {
                this.verificationQuestions = this.verificationQuestions.map((q, index) => ({
                    ...q,
                    answer: client.verification_answers[index],
                }));
            } else {
                // Reset answers
                this.verificationQuestions = this.verificationQuestions.map(q => ({
                    ...q,
                    answer: false,
                }));
            }
            this.showVerificationModal = true;
        },
        /**
         * Saves updated verification answers for the selected client.
         *
         * This method serializes the current verification answers into a `FormData` object,
         * then sends a PATCH request to update the client’s answers. If successful, the client
         * list is refreshed, and a success message is displayed.
         *
         * @method
         */
        submitVerification() {
            if (!this.selectedClient) {
                return;
            }
            // Prepare the updated verification_answers
            const updatedAnswers = this.verificationQuestions.map(q => q.answer);

            // Send a request to update the client
            const formData = new FormData();
            formData.append('verification_answers', JSON.stringify(updatedAnswers));

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            };

            apiClient
                .patch(`/api/green-house-clients/${this.selectedClient.id}/`, formData, config)
                .then(async () => {
                    // Update the client in clientsList
                    await this.fetchClientsList();
                    const index = this.clientsList.findIndex(c => c.id === this.selectedClient.id);
                    if (index !== -1) {
                        this.clientsList[index].verification_answers = updatedAnswers;
                    }
                    this.showVerificationModal = false;
                    showAlertModal(this.$store, 'Verificarea a fost salvată.', 'success', 5000);
                })
                .catch(error => {
                    console.error('Error updating verification:', error);
                    showAlertModal(this.$store, 'A apărut o eroare la salvarea verificării.', 'danger', 5000);
                });
        },
        /**
         * Fetches regions and cities data, populating `regions` and `cities` arrays with the parsed data.
         *
         * This method retrieves a JSON file of regions and cities, parses it, and assigns
         * the appropriate information to `regions` and `cities`. This allows the component to
         * support region-based filtering in the client list.
         *
         * @async
         * @method
         */
        async loadRegionsAndCities() {
            try {
                const response = await fetch("/cities.json");
                const data = await response.json();

                this.regions = data
                    .filter((item) => item.model === "cities_light.region")
                    .map((item) => ({
                        id: item.pk,
                        name: item.fields.name,
                        display_name: item.fields.display_name,
                    }));

                this.cities = data
                    .filter((item) => item.model === "cities_light.subregion")
                    .map((item) => ({
                        id: item.pk,
                        name: item.fields.name,
                        display_name: item.fields.display_name,
                        region_id: item.fields.region,
                    }));
            } catch (error) {
                console.error("Error loading regions and cities:", error);
            }
        },
        /**
         * Retrieves the name of a region based on its ID.
         *
         * This method searches the `regions` array for the region object with a matching ID,
         * returning the region’s name if found, or an empty string otherwise.
         *
         * @param {number} regionId - The ID of the region to retrieve.
         * @returns {string} - The name of the region.
         */
        getRegionName(regionId) {
            const region = this.regions.find((r) => r.id === regionId);
            return region ? region.name : '';
        },
        /**
         * Navigates to the client form view for creating a new client.
         *
         * This method uses Vue Router to navigate to the client form view, facilitating the addition of a new client.
         *
         * @method
         */
        addNewClient() {
            // Navigate to the client form view for adding a new client
            this.$router.push({name: 'GreenHouseClientForm'});
        },
        /**
         * Navigates to the client form view for editing the specified client.
         *
         * This method uses Vue Router to navigate to the client form view, with the specified client’s
         * ID passed as a route parameter to populate the form with the client's existing data.
         *
         * @param {Object} client - The client object to edit.
         */
        editClient(client) {
            // Navigate to the client form view for editing the client
            this.$router.push({
                name: 'GreenHouseClientForm',
                params: {clientId: client.id},
            });
        },
        /**
         * Retrieves the display title for a client's status based on the status value.
         *
         * This method checks the client's status and returns the appropriate title, including conditional
         * titles based on whether the user is a superuser. It’s used for consistent display of client statuses.
         *
         * @param {number} statusValue - The client's status value.
         * @returns {string} - The corresponding status title.
         */
        getStatusTitle(statusValue) {
            if (statusValue === 5) {
                return this.isSuperUser ? 'Confirmat' : 'Finalizat';
            }
            const status = this.availableStatusOptions.find((option) => option.value === statusValue);
            return status ? status.title : '';
        },
        /**
         * Deletes the specified client after user confirmation.
         *
         * This method prompts the user for confirmation before deleting the client, sends a DELETE request if confirmed,
         * removes the client from `clientsList`, and displays a success or error message based on the outcome.
         *
         * @async
         * @param {Object} client - The client object to delete.
         */
        async removeClient(client) {
            const confirmDelete = confirm('Sigur doriți să ștergeți acest client?');
            if (confirmDelete) {
                try {
                    await apiClient.delete(`/api/green-house-clients/${client.id}/`);
                    this.clientsList = this.clientsList.filter((c) => c.id !== client.id);
                    showAlertModal(this.$store, 'Clientul a fost șters cu succes.', 'success', 5000);
                } catch (error) {
                    console.error('Error deleting client:', error);
                    showAlertModal(
                        this.$store,
                        'A apărut o eroare la ștergerea clientului.',
                        'danger',
                        12000
                    );
                }
            }
        },
        /**
         * Fetches the list of clients from the server and assigns it to `clientsList`.
         *
         * This method optionally filters clients based on the current user's ID if they are not a superuser.
         * It also combines `inverter` and `inverter_type` fields into `inverter_display` for concise display.
         *
         * @async
         * @method
         */
        async fetchClientsList() {
            try {
                const userId = sessionStorage.getItem('userId');
                const isSuperUser = JSON.parse(sessionStorage.getItem('superUser'));

                const params = {};
                if (!isSuperUser) {
                    params.userId = userId;
                }

                const response = await apiClient.get('/api/green-house-clients/', {params});
                if (response && response.data) {
                    // Combine inverter and inverter_type fields into one property for each client
                    this.clientsList = response.data.map(client => ({
                        ...client,
                        inverter_display: client.inverter ? `${client.inverter}, ${client.inverter_type || 'On grid'}` : '---',
                        total_documents: client.has_mortgage ? 6 : 4,
                    }));
                } else {
                    this.clientsList = [];
                }
            } catch (error) {
                console.error("An error occurred while fetching the clients:", error);
            }
        },
        /**
         * Calculates the number of uploaded documents for a client.
         *
         * This method checks which document fields are present in the client object and returns
         * the count, allowing for dynamic tracking of document upload progress.
         *
         * @param {Object} client - The client object whose documents are being counted.
         * @returns {number} - The count of uploaded documents (0 to 4).
         */
        getDocumentCount(client) {
            let count = 0;
            if (client.identification_document) count += 1;
            if (client.cf_document) count += 1;
            if (client.contract_document) count += 1;
            if (client.connection_certificate_document) count += 1;
            if (client.has_mortgage) {
                if (client.bank_contract_document) count += 1;
                if (client.latest_payment_proof_document) count += 1;
            }
            return count;
        },
        /**
         * Determines the color of a client’s document status chip based on the presence of required documents.
         *
         * This method evaluates the presence of each document field in the client object and returns
         * a color string representing their progress:
         * - 'error' for no documents
         * - 'warning' for partial completion or specific missing documents
         * - 'success' for complete sets or if connection certificate requirements are fulfilled
         *
         * @param {Object} client - The client object whose document status chip color is determined.
         * @returns {string} - The color string ('error', 'warning', or 'success').
         */
        getDocumentChipColor(client) {
            const hasIdentification = Boolean(client.identification_document);
            const hasCFDocument = Boolean(client.cf_document);
            const hasContractDocument = Boolean(client.contract_document);
            const hasConnectionCertificateDocument = Boolean(client.connection_certificate_document);
            const connectionCertificateFulfilled = client.has_connection_certificate || hasConnectionCertificateDocument;

            // Additional mortgage-related documents
            const hasBankContract = client.has_mortgage && Boolean(client.bank_contract_document);
            const hasLatestPaymentProof = client.has_mortgage && Boolean(client.latest_payment_proof_document);

            // Step 1: Calculate document count and check if all required documents are present
            const totalDocuments = client.total_documents;
            let documentCount = 0;
            if (hasIdentification) documentCount += 1;
            if (hasCFDocument) documentCount += 1;
            if (hasContractDocument) documentCount += 1;
            if (hasConnectionCertificateDocument) documentCount += 1;
            if (hasBankContract) documentCount += 1;
            if (hasLatestPaymentProof) documentCount += 1;

            const allDocumentsPresent = documentCount === totalDocuments;

            // Explicitly check that all essential documents (non-connection related) are present
            const allEssentialDocumentsPresent = hasIdentification && hasCFDocument && hasContractDocument;
            const allMortgageDocumentsPresent = !client.has_mortgage || (hasBankContract && hasLatestPaymentProof);

            // Step 2: Determine chip color based on conditions

            // Case 1: All required documents are present
            if (allDocumentsPresent) {
                return 'success';
            }

            // Case 2: Connection certificate fulfilled, and all essential and mortgage documents are present
            if (connectionCertificateFulfilled && allEssentialDocumentsPresent && allMortgageDocumentsPresent) {
                return 'success';
            }

            // Case 3: Only missing the connection certificate and it is not fulfilled
            if (!connectionCertificateFulfilled && documentCount === totalDocuments - 1) {
                return 'warning';
            }

            // Case 4: Connection certificate fulfilled but other essential/mortgage documents are missing
            if (connectionCertificateFulfilled && (!allEssentialDocumentsPresent || !allMortgageDocumentsPresent)) {
                return 'warning';
            }

            // Default case: Partial documents present but do not satisfy any complete cases
            return 'warning';
        },
        /**
         * Returns a comma-separated list of the client’s uploaded document names.
         *
         * This method examines each document field in the client object, adding the name of each
         * uploaded document to a list and returning it as a formatted string. If no documents are uploaded,
         * it returns "Nici un document".
         *
         * @param {Object} client - The client object whose document list is being generated.
         * @returns {string} - The comma-separated list of document names or "Nici un document".
         */
        getDocumentList(client) {
            const documents = [];
            if (client.identification_document) documents.push('Carte de identitate');
            if (client.cf_document) documents.push('C.F.');
            if (client.contract_document) documents.push('Contract');
            if (client.connection_certificate_document) documents.push('Certificat racordare');
            if (client.has_mortgage) {
                if (client.bank_contract_document) documents.push('Contract bancă');
                if (client.latest_payment_proof_document) documents.push('Dovada ultimei plăți');
            }
            return documents.length > 0 ? documents.join(', ') : 'Nici un document';
        },
    },
};
</script>

<style scoped>
.ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px; /* Adjust this value as needed */
    display: block;
}

.confirmed-text {
    color: #228B22; /* Forest green or any preferred green color */
}

.danger-text {
    color: rgba(255, 39, 39, 1);
}

.info-text {
    color: rgb(0, 0, 255);
}
</style>
